<!-- 批量改价 -->
<template>
  <section class="cont batchChange" @mouseup="selectDateList=[]">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>房价设置</el-breadcrumb-item>
        <el-breadcrumb-item>批量改价</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-row class="content-box">
      <!-- 第一步 -->
      <div class="step-title">
        <div>1</div> <p>选择渠道</p>
      </div>
      <div class="step-content">
        <el-alert type="success" :closable="false" class="_alert">
          <template slot='title'>
            <div>已选 {{checkedChannels.length}} 个渠道
              <el-button type="text" @click="reset('1')">重置</el-button>
            </div>
          </template>
        </el-alert>
        <div class="channel">
          <el-checkbox :indeterminate="channelIndeterminate" v-model="checkAllChannel" @change="handleCheckAllChangeChannel" class="week">全选</el-checkbox>
          <span style="padding-left: 14px;color:#606266;margin-right: 30px;">系统</span>
          <el-checkbox-group v-model="checkedChannels" @change="handleCheckedChannelChange">
            <el-checkbox v-for="channel in channels" :label="channel" :key="channel" border class="week">{{channel | channelName(that)}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 第二步 -->
      <div class="step-title">
        <div>2</div> <p>选择房型</p>
      </div>
      <div class="step-content">
        <el-alert type="success" :closable="false" class="_alert">
          <template slot='title'>
            <div>已选 {{checkedRoomType.length}} 个本地房型, 对应 {{checkedChannels.length}}个渠道房型
              <el-button type="text" @click="reset('2')">重置</el-button>
            </div>
          </template>
        </el-alert>
        <div class="channel">
          <el-checkbox class="week" :indeterminate="roomTypeIndeterminate" v-model="checkAllRoomType" @change="handleCheckAllChangeRoomType" :disabled="!checkedChannels.length" style="padding-right: 16px;">全选</el-checkbox>
          <el-checkbox-group v-model="checkedRoomType" @change="handleCheckedRoomTypeChange" v-if="checkedChannels.length">
            <el-checkbox class="week" v-for="(item,index) in roomTypeOptions" :label="item" :key="index" border>{{item}}</el-checkbox>
          </el-checkbox-group>
          <div v-else class="disabled"> 请先选择价格渠道</div>
        </div>
      </div>
      <!-- 第三步 -->
      <div class="step-title">
        <div>3</div> <p>选择日期</p>
      </div>
      <div class="step-content">
        <el-alert type="success" :closable="false" class="_alert">
          <template slot='title'>
            <div>已选 {{data.length}} 天
              <el-button type="text" @click="reset('3')">重置</el-button>
            </div>
          </template>
        </el-alert>
        <div class="content">
          <!-- 日历 -->
          <div class="calendar">
            <!-- 今年 -->
            <div class="calendar-month" v-for="(month,index) in 13-currentMonth" :key="`${currentYear}年${currentMonth*1+index}月`">
              <div class="calendar-month-title">{{currentYear}} 年 {{ MM(currentMonth*1+index) }} 月</div>
              <ul class="calendar-month-box">
                <li class="text-tip">日</li>
                <li class="text-tip">一</li>
                <li class="text-tip">二</li>
                <li class="text-tip">三</li>
                <li class="text-tip">四</li>
                <li class="text-sub">五</li>
                <li class="text-sub">六</li>
                <li v-for="(week,inx) in whatWeek(currentYear,currentMonth*1+index)" :key="`${currentYear}年${currentMonth*1+index}月${inx}`"></li>
                <li v-for="day in whatdays(currentYear,currentMonth*1+index)" :key="`${currentMonth*1+index}月${day}日`"
                  @mouseover="select($event,currentYear*1,currentMonth*1+index,day)" :style="selectStyle(currentYear*1,currentMonth*1+index,day)"
                  @mousedown="optionDateAction($event,currentYear*1,currentMonth*1+index,day)" @mouseup="optionDateEnd($event,currentYear*1,currentMonth*1+index,day)" 
                  :class="{isSelected:ischeckdata(currentYear,currentMonth*1+index,day),clickable:isclickable(currentYear*1,currentMonth*1+index,day),isOve:isOve(currentYear,currentMonth*1+index,day) }">{{day}}</li>
              </ul>
            </div>
            <!-- 明年 -->
            <div class="calendar-month" v-for="month in currentMonth-1" :key="`${currentYear*1+1}年${month}月`">
              <div class="calendar-month-title">{{currentYear*1+1}} 年 {{ MM(month) }} 月</div>
              <ul class="calendar-month-box">
                <li class="text-tip">日</li>
                <li class="text-tip">一</li>
                <li class="text-tip">二</li>
                <li class="text-tip">三</li>
                <li class="text-tip">四</li>
                <li class="text-sub">五</li>
                <li class="text-sub">六</li>
                <li v-for="(week,index) in whatWeek(currentYear*1+1,month)" :key="`${currentYear*1+1}年${month}月${index}`"></li>
                <li v-for="day in whatdays(currentYear*1+1,month)" :key="`${month}月${day}日`" class="clickable"
                 @mouseenter="select($event,currentYear*1+1,month,day)" :style="selectStyle(currentYear*1+1,month,day)"
                 @mousedown="optionDateAction($event,currentYear*1+1,month,day)" @mouseup="optionDateEnd($event,currentYear*1+1,month,day)"
                 :class="{isSelected:ischeckdata(currentYear*1+1,month,day)}">{{day}}</li>
              </ul>
            </div>
          </div>
          <div class="content-right">
            <p style="margin-bottom: 24px">适用周几</p>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <el-checkbox-group v-model="checkedWeek" @change="handleCheckedChange">
              <el-checkbox class="week" label="0" border>周日</el-checkbox>
              <el-checkbox class="week" label="1" border>周一</el-checkbox>
              <el-checkbox class="week" label="2" border>周二</el-checkbox>
              <el-checkbox class="week" label="3" border>周三</el-checkbox>
              <el-checkbox class="week" label="4" border>周四</el-checkbox>
            </el-checkbox-group>
            <p style="margin:16px 0;color: rgb(173, 191, 221);">周末</p>
            <el-checkbox-group v-model="checkedWeek" @change="handleCheckedChange">
              <el-checkbox class="week" label="5" border>周五</el-checkbox>
              <el-checkbox class="week" label="6" border>周六</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <!-- 第四步 -->
      <div class="step-title">
        <div>4</div> <p>设置价格</p>
      </div>
      <div class="step-content">
        <div class="channel" style="padding: 24px;">
          <span style="margin-right: 16px;" :class="{forbidden:!checkedRoomType.length}">改价方式</span>
          <el-radio-group v-model="radio" @change="altermode" :disabled="!checkedRoomType.length">
            <el-radio :label="0">指定金额</el-radio>
            <el-radio :label="1">规则调价</el-radio>
          </el-radio-group>
          <div style="margin:16px 0 0 70px;padding: 16px;background: #f0f4ff;">
            <span style="margin-right: 42px;" :class="{forbidden:!checkedRoomType.length}">价格统一设置为</span>
            <el-input v-model="equallyPrice" placeholder="请输入指定金额" :disabled="!checkedRoomType.length" style="width: 220px;" @change="changePrice('1')" :onkeyup='dataValidation' clearable v-if="!radio" key="1">
              <template slot="prepend">￥</template>
            </el-input>
            <template v-else>
              <el-select v-model="channel" style="width: 140px;" :disabled="!checkedRoomType.length">
                <el-option label="按门市价" value="按门市价"></el-option>
                <el-option label="按渠道价格" value="按渠道价格"></el-option>
              </el-select>
              <el-select v-model="changePriceWay" style="margin:0 8px;width: 140px;" :disabled="!checkedRoomType.length">
                <el-option label="加" value="+"></el-option>
                <el-option label="减" value="-"></el-option>
                <el-option label="乘" value="×"></el-option>
                <el-option label="除" value="÷"></el-option>
              </el-select>
              <el-input v-model="parameterPrice" placeholder="请输入调价参数" :disabled="!checkedRoomType.length" style="width: 150px;" @change="changePrice('2')" :onkeyup='dataValidation' clearable></el-input>
            </template>
            <div v-if="!checkedRoomType.length" style="color: #C0C4CC;display: inline-block;position: relative;left: 363px;"> 请先选择价格渠道和房型</div>
          </div>
          <el-table style="margin-top: 24px;" border fit :stripe="true" :data="tableData" :header-cell-style="{background: '#f0f3fc'}" v-if="checkedRoomType.length">
            <el-table-column prop="date" label="本地房型"></el-table-column>
            <el-table-column prop="name" label="门市价">
              <template slot-scope="scope">
                <div>{{ scope.row.name }}
                  <span style="font-size: 21px;" v-if="radio"> {{changePriceWay}} </span>
                  <el-input v-model="scope.row.price" :placeholder="radio?'参数':'金额'" style="width: 160px;" :onkeyup='dataValidation' clearable>
                    <template slot="prepend">￥</template>
                  </el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="30">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteBt(scope.$index)">
                  <el-link type="danger">删除</el-link>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 24px;text-align: end;" v-if="checkedRoomType.length">
            <el-button type="primary" style="margin-left: 16px;" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { pms } from '@/api/interface/pms';
import { dateFactory,getDict } from '@/common/js/common';
const weekOptions = ['0','1', '2', '3', '4', '5', '6'];
export default {
  data(){
    return{
      that:this,
      hotelId:'',         // 酒店ID
      roomTypeList:[],    // 房型列表
      beginTime:"",       // 今天的日期 "2022-02-26"

      // 选择渠道
      checkAllChannel:false,
      checkedChannels:[],
      channels: [],
      channelIndeterminate: false,
      // 房型
      checkAllRoomType: false,
      checkedRoomType: [],
      roomTypeOptions: [],
      roomTypeIndeterminate: false,
      // 星期
      checkAll: false,
      checkedWeek: [],
      week: weekOptions,
      isIndeterminate: false,

      currentYear:"2022",  // 当前年份
      currentMonth:"02",   // 当前月份
      data:[],             // 选中的日期

      actionData:[],       // 多选开始日期
      endData:[],          // 多选结束日期
      selectDateList:[],   // 鼠标拖选日期数组

      radio: 0,            // 改价类型（0,1）
      channel:"按门市价",   // 渠道
      changePriceWay:"+",  // 改价方式(+,-,×,÷)
      equallyPrice:"",     // 统一价格
      parameterPrice:"",   // 价格参数
      price:[],            // 价格

      tableData: [],       // 表格数据
      prompt: '提示！',
      del_success: "房型删除成功！",
      update_success: "房价修改成功！",
      confirm_remove: '确定要删除吗？',
    }
  },
  computed:{
    ...mapState(['dictData','hotelInfo']),
    // 验证输入金额
    dataValidation(){
      return 'value=value.replace(/[^\\d.]/g,"").replace(".", "$#$").replace(/\\./g, "").replace("$#$", ".").replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, "$1$2.$3").replace(/^\\./g, "").replace(/\\.{2,}/g, ".")';
    },
    whatWeek(){
      return function(year,month){
        let week = new Date(year,month-1,1);
        return week.getDay();
      }
    },
    whatdays(){
      return function(year,month){
        let day = new Date(year,month,0);   //最后一个参数为0,意为获取2022年2月一共多少天
        return day.getDate();
      }
    },
    MM(){
      return function(m){
        if (m<10) {
          m = "0" + m;
        }
        return m
      }
    },
    ischeckdata(){
      return function(y,m,d){
        if (m<10) {
          m = "0" + m;
        }
        if (d<10) {
          d= "0" + d;
        }
        return this.data.some(item=>item === `${y}-${m}-${d}`)
      }
    },
    isclickable(){
      return function(y,m,d){
        if (m<10) {
          m = "0" + m;
        }
        if (d<10) {
          d= "0" + d;
        }
        return `${y}-${m}-${d}` >= this.beginTime ;
      }
    },
    isOve(){
      return function(y,m,d){
        if (m<10) {
          m = "0" + m;
        }
        if (d<10) {
          d= "0" + d;
        }
        return `${y}-${m}-${d}` < this.beginTime ;
      }
    },
    // 鼠标拖选样式
    selectStyle(){
      return function(y,m,d){
        if (this.selectDateList.some(item=>item === `${y}-${m}-${d}`)) {
          return 'color: #fff;background-color: #053dc8'
        } else {
          return ''
        }
      }
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getRoomType();
      }
    }
  },
  created(){
    // 今天的日期 "2022-02-26"
    this.beginTime = dateFactory.getDistanceToday(0,false);

    // 日期处理
    let date = new Date();
    this.currentYear = date.getFullYear()+"";
    this.currentMonth = date .getMonth()+1+"";
  },
  mounted(){
    this.hotelId = this.hotelInfo.id;
    getDict(["order-source"],false);
    this.channels = this.dictData['order-source-list'].map(el=>el.value);
    this.getRoomType();
  },
  methods:{
    // 选择渠道
    handleCheckAllChangeChannel(val) {
      this.checkedChannels = val ? this.channels : [];
      this.channelIndeterminate = false;
    },
    handleCheckedChannelChange(value) {
      let checkedChannelCount = value.length;
      this.checkAllChannel = checkedChannelCount === this.channels.length;
      this.channelIndeterminate = checkedChannelCount > 0 && checkedChannelCount < this.channels.length;
      this.reset('2');
    },
    // 选择房型
    handleCheckAllChangeRoomType(val) {
      this.checkedRoomType = val ?  [...this.roomTypeOptions ]: [];
      let arr = [];
      // 添加房型
      if (val) {
        for (let index = 0; index < this.roomTypeOptions.length; index++) {
          arr.push({date: this.roomTypeOptions[index],name: '门市价',price:''})
        }
        this.tableData = arr;
      }
      this.roomTypeIndeterminate = false;
    },
    handleCheckedRoomTypeChange(value) {
      let checkedCount = value.length;
      this.checkAllRoomType = checkedCount === this.roomTypeOptions.length;
      this.roomTypeIndeterminate = checkedCount > 0 && checkedCount < this.roomTypeOptions.length;
      let arr = [];
      // 添加房型
      for (let index = 0; index < value.length; index++) {
        arr.push({date: value[index],name: '门市价',price:''})
      }
      this.tableData = arr;
    },
    // 选择星期
    handleCheckAllChange(val) {
      this.checkedWeek = val ? weekOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.week.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.week.length;
    },
    // 获取房型列表
    getRoomType(){
      const url = pms.getRoomTypeList;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        roomTypeSource:'PMS',
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.roomTypeList = res.records
          let arr = [];
          for (let index = 0; index < res.records.length; index++) {
            arr.push(res.records[index].roomTypeName);
          }
          this.roomTypeOptions = arr;
        }
      })
    },
    // 重置
    reset(a){
      if( a==="1" ){
        this.handleCheckAllChangeChannel(false);
        this.handleCheckedChannelChange([]);

        this.handleCheckAllChangeRoomType(false);
        this.handleCheckedRoomTypeChange([]);

        this.altermode();
        this.radio = 0;
        this.tableData = [];
      }else if( a==="2" ){
        this.handleCheckAllChangeRoomType(false);
        this.handleCheckedRoomTypeChange([]);

        this.altermode();
        this.radio = 0;
        this.tableData = [];
      }else if( a==="3" ){
        this.data = [];
      }
    },
    // 日期选中事件
    optionDate(y,m,d){
      // 后台需要的时间 2022-02-26
      if (m<10) {
        m = "0" + m;
      }
      if (d<10) {
        d= "0" + d;
      }
      if (this.data.some(item=>item === `${y}-${m}-${d}`)) {
        let index = this.data.indexOf(`${y}-${m}-${d}`);
        // 根据下标删除对应日期
        this.data.splice(index, 1);
      }else{
        if (`${y}-${m}-${d}` >= this.beginTime) {
          this.data.push(`${y}-${m}-${d}`)
        }
      }
    },
    // 日期多选事件
    optionAllDate(startTime,endTime){
      let st = ''
      let et = ''
      //判断起止时间前后
      if(startTime[0]>endTime[0]||
      (startTime[0]===endTime[0]&&startTime[1]>endTime[1])||
      (startTime[0]===endTime[0]&&startTime[1]===endTime[1]&&startTime[2]>endTime[2])
      ){
        st = new Date(endTime[0],endTime[1]-1,endTime[2])
        et = new Date(startTime[0],startTime[1]-1,startTime[2])
      }else{
        st = new Date(startTime[0],startTime[1]-1,startTime[2])
        et = new Date(endTime[0],endTime[1]-1,endTime[2])
      }
        while((et.getTime()-st.getTime())>=0){
          let year = st.getFullYear();
          let month = st.getMonth()+1;
          let day = st.getDate();
          this.optionDate(year,month,day)
          st.setDate(st.getDate()+1);
        }
    },
    // 鼠标拖选
    select(e,y,m,d){
      if (this.selectDateList.length) {
        if (`${y}-${m<10?'0'+m:m}-${d<10?'0'+d:d}` >= this.beginTime) {
          let st = ''
          let et = ''
          let startTime = this.actionData
          let endTime = [y,m,d]
          this.selectDateList = [`${this.actionData[0]}-${this.actionData[1]<10?'0'+this.actionData[1]:this.actionData[1]}-${this.actionData[2]<10?'0'+this.actionData[2]:this.actionData[2]}`]
          //判断起止时间前后
          if(startTime[0]>endTime[0]||
          (startTime[0]===endTime[0]&&startTime[1]>endTime[1])||
          (startTime[0]===endTime[0]&&startTime[1]===endTime[1]&&startTime[2]>endTime[2])
          ){
            st = new Date(endTime[0],endTime[1]-1,endTime[2])
            et = new Date(startTime[0],startTime[1]-1,startTime[2])
          }else{
            st = new Date(startTime[0],startTime[1]-1,startTime[2])
            et = new Date(endTime[0],endTime[1]-1,endTime[2])
          }
          while((et.getTime()-st.getTime())>=0){
            let year = st.getFullYear();
            let month = st.getMonth()+1;
            let day = st.getDate();
            if (this.selectDateList.some(item=>item !== `${year}-${month}-${day}`)) {
              this.selectDateList.push(`${year}-${month}-${day}`)
            }
            st.setDate(st.getDate()+1);
          }
        }
      }
    },
    //鼠标左键按下开始
    optionDateAction(e,y,m,d){
      this.actionData = [y,m,d]
      if (`${y}-${m<10?'0'+m:m}-${d<10?'0'+d:d}` >= this.beginTime) {
        this.selectDateList.push(`${y}-${m}-${d}`)
      }
    },
    //鼠标左键抬起结束
    optionDateEnd(e,y,m,d){
      this.endData = [y,m,d]
      if(this.actionData.toString()===this.endData.toString()){
        this.optionDate(y,m,d)
      }else{
        this.optionAllDate(this.actionData,this.endData)
      }
      this.selectDateList=[]
    },
    // 切换改价类型触发
    altermode(){
      this.equallyPrice="";
      this.parameterPrice="";
      this.channel = "按门市价";
      this.changePriceWay = "+";
    },
    // 设置统一价格
    changePrice(state){
      if (state==="1") {
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].price = this.equallyPrice;
        }
      }else{
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].price = this.parameterPrice;
        }
      }
    },
    // 删除
    deleteBt(index){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: "warning",
      }).then(() => {
        this.$message({showClose: true,message: this.del_success,type: 'success'});
        let arr = this.checkedRoomType;
        arr.splice(index,1);
        this.handleCheckedRoomTypeChange(arr);
      });
    },
    // 完成
    submit(){
      if (!this.data.length) {
        this.$message({showClose: true,message:"请至少选择一个日期",type: 'error'});
      }else{
        let arr =[];
        for (let index = 0; index < this.checkedRoomType.length; index++) {
          for (let i = 0; i < this.roomTypeList.length; i++) {
            if (this.roomTypeList[i].roomTypeName === this.checkedRoomType[index]) {
              arr.push({roomTypeId:this.roomTypeList[i].id} )  ;
            }
          }
          if (this.tableData[index].price) {
            arr[index]["roomPrice"] = this.tableData[index].price;
          } else {
            this.$message({showClose: true,message:"请输入修改价格",type: 'error'});
            return false
          }
        }
        const url = pms.setRoomPrice;
        const param = {
          hotelId:this.hotelId,                        // 酒店ID
          dateString:this.data.toString(),             // 选择日期
          changePriceType:this.radio,                  // 改价类型（0,1）
          changePriceWay:this.changePriceWay,          // 改价方式(+,-,×,÷)
          week:this.checkedWeek.toString(),            // 星期（多个逗号分隔）
          priceCalendarDTOS:arr,                       // 房型-房价集合
          pmsSourceTypeList:this.checkedChannels       // 渠道
        }
        this.$axios.post(url, param, 'json').then((res) => {
          if (res.success) {
            this.$message({showClose: true,message: this.update_success,type: 'success'});
            this.reset("1");
            this.handleCheckAllChange(false);
            this.handleCheckedChange([]);
            this.data = [];
          }
        })
      }
    }
  },
  filters:{
    channelName(val,that){
      that.dictData['order-source-list'].forEach(item=>{
        if(item.value === val)  val = item.label
      })
      return val
    }
  }
}
</script>

<style lang="scss" scoped>
.batchChange {
  .step-title{
    display: flex;
    div {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border: 1px solid rgb(51, 51, 51);
      border-radius: 50%;
    }
    p {
      margin-left: 8px;
      line-height: 24px;
    }
  }
  .step-content {
    width: 100%;
    margin-left: 12px;
    padding-top: 20px;
    padding-bottom: 32px;
    padding-left: 24px;
    font-size: 14px;
    border-left: 1px dotted rgb(218, 227, 242);
    ._alert {
      padding: 5.5px 24px;
      background-color: rgb(240, 244, 255);
      border: 1px solid #409eff;
      color: #333;
      /deep/ .el-alert__title{
        font-size: 14px;
        line-height: 21px;
        .el-button--text{
          padding: 0;
          padding-left: 24px;
          color: #409eff;
        }
      }
    }
    .channel {
      margin-top: 16px;
      padding: 24px 0 24px 24px;
      border: 1px solid #E9EAED;
      background-color: rgb(250, 251, 254);
      .el-checkbox-group{
        display: inline-block;
        .el-checkbox{
          &:hover{
            border-color: #409eff;
          }
        }
      }
      .forbidden{
        color: #C0C4CC;
      }
      .week{
        margin:0 16px 16px 0;
      }
      .disabled{
        color: #C0C4CC;
        display: inline-block;
        position: relative;
        left: calc(50% - 122PX) ;
      }
    }
    .content{
      display: flex;
      padding: 24px 0;
      margin-top: 16px;
      border: 1px solid #E9EAED;
      background-color: #fafbfe;
      .calendar {
        width: calc(100% - 250px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
       
        .calendar-month{
          width: 252px;
          margin: 24px 24px 0 24px;
          .calendar-month-title{
            text-align: center;
            font-size: 14px;
            padding-bottom: 8px;
            user-select: none;
          }
          .calendar-month-box{
            display: flex;
            flex-wrap: wrap;
            color: #666;
            .isSelected{
              background: #b3c8ff;
              color: #162d53;
            }
            .isOve{
              background: linear-gradient(135deg,transparent 49.5%,#ccc 0,#ccc 50.5%,transparent 0);
              background-color: #f0f0f0;
              color: #999;
              cursor: default;
            }
            .clickable{
              &:active{
                color: #fff !important;
                background-color:#053dc8 !important;
              }
            }
          }
          .text-sub{
            color: #666;
            cursor: default;
          }
          .text-tip {
            color: #999;
            cursor: default;
          }
          ul{
            li{
              list-style-type:none;
              text-align: center;
              width: 36px;
              height: 36px;
              line-height: 36px;
              font-size: 14px;
              cursor: pointer;
              user-select: none;
              &:nth-of-type(7n), &:nth-of-type(7n-1){
                background: #f0f3fc;
              }
            }
          }
        }
      }
      .content-right{
        width: 250px;
        padding: 24PX 24px 0 24px;
        border-left: 1px solid #E9EAED;
        .el-checkbox-group{
          margin-top: 16px;
          .el-checkbox{
            &:hover{
              border-color: #409eff;
            }
          }
          .week{
            margin:0 16px 16px 0;
          }
        }
      }
    }
  }
}
</style>
